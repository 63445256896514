import _defineProperty from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { ExperienceMapper } from '@ninetailed/experience.js-utils';
import { createContext, useContext } from 'react';
import { useExperience } from '@ninetailed/experience.js-next';

// SettingsContext = createContext({});

// function SettingsProvider(props) {
//   const { settings } = props.config?.fields || {};
//   return (
//     <SettingsContext.Provider
//       value={(settings || []).reduce((acc, current) => {
//         if (current) {
//           return { ...acc, [current.fields.settingKey]: current };
//         } else return acc;
//       }, {})}
//     >
//       {props.children}
//     </SettingsContext.Provider>
//   );
// }

export var hasExperiences = function hasExperiences(entry) {
  return entry.fields.nt_experiences !== undefined;
};
export function parseExperience(experience) {
  var _experience$descripti, _experience$sys;
  return _objectSpread(_objectSpread({
    name: experience.ntName,
    type: experience.ntType,
    config: experience.ntConfig
  }, experience.ntAudience ? {
    audience: {
      id: experience.ntAudience.ntAudienceId,
      name: experience.ntAudience.ntName
    }
  } : {}), {}, {
    description: (_experience$descripti = experience.description) !== null && _experience$descripti !== void 0 ? _experience$descripti : "",
    id: experience === null || experience === void 0 ? void 0 : (_experience$sys = experience.sys) === null || _experience$sys === void 0 ? void 0 : _experience$sys.id,
    variants: experience.ntVariantsCollection.items.map(function (variant) {
      var _variant$sys;
      return {
        id: variant === null || variant === void 0 ? void 0 : (_variant$sys = variant.sys) === null || _variant$sys === void 0 ? void 0 : _variant$sys.id,
        // TODO: adapt this to use with with different components, or adapt components to use the same structure
        promoData: _objectSpread({}, variant)
      };
    })
  });
}
export function filterAndMapExperiences(experiences) {
  if (!experiences) {
    return null;
  }

  // console.log('filterAndMapExperiences experiences', experiences)
  // console.log('filterAndMapExperiences experiences.map', experiences
  // 	.map((experience) => parseExperience(experience)));
  var mappedExperiences = experiences.map(function (experience) {
    return parseExperience(experience);
  });

  // 	// console.log('filterAndMapExperiences mappedExperiences.filter', mappedExperiences
  //   //   .filter(ExperienceMapper.isExperienceEntry));
  // 		console.log('filterAndMapExperiences mappedExperiences[0]', mappedExperiences[0])
  // 		console.log('filterAndMapExperiences mappedExperiences isExperience', ExperienceMapper.isExperienceEntry(mappedExperiences[0]))
  // 		// console.log('filterAndMapExperiences mappedExperiences isExperiment', ExperienceMapper.isExperiment(mappedExperiences[0]))

  // 		console.log('filterAndMapExperiences mappedExperiences isExperience!!!!', ExperienceMapper.isExperienceEntry(experiences[0]))
  // 		// console.log('filterAndMapExperiences mappedExperiences isExperiment!!!!', ExperienceMapper.isExperiment(experiences[0]))
  // 	// return;
  return mappedExperiences.filter(ExperienceMapper.isExperienceEntry).map(ExperienceMapper.mapExperience);
}
export function parseAudience(audience) {
  var _audience$ntDescripti;
  return {
    id: audience.ntAudienceId,
    name: audience.ntName,
    description: (_audience$ntDescripti = audience.ntDescription) !== null && _audience$ntDescripti !== void 0 ? _audience$ntDescripti : ""
  };
}
export function mapAudiences(audiences) {
  return audiences.map(function (audience) {
    return parseAudience(audience);
  });
}
export var parseExperiences = function parseExperiences(entry) {
  console.log('entry', entry);

  // console.log('isExperienceEntry', ExperienceMapper.isExperienceEntry(entry.ntExperiencesCollection))
  // console.log('%c isExperienceEntry ntExperiencesCollection', 'color: cyan', entry.ntExperiencesCollection.items
  // 	.filter(ExperienceMapper.isExperienceEntry));
  // const mappedExperiences = (entry.ntExperiencesCollection?.items || [])
  // 	.map((experience) => {
  // 		console.log('experience', experience)
  // 		console.log('%c isExperienceEntry', 'color: green', ExperienceMapper.isExperienceEntry(experience))
  // 		return {
  // 			id: experience.ntExperienceId,
  // 			name: experience.ntName,
  // 			description: experience.description ?? "",
  // 			type: experience.ntType,
  // 			config: experience.ntConfig,
  // 			// This syntax accounts for the possibility of an audience not being set on an experiment
  // 			...(experience.ntAudience
  // 				? { 
  // 						audience: {
  // 							id: experience.ntAudience.ntAudienceId,
  // 							// If mapping for the Preview Plugin, this displays audience names
  // 							name: experience.ntAudience.ntName
  // 						},
  // 					}
  // 				: {}),
  // 			variants: experience.ntVariantsCollection?.items.map((variant) => {
  // 				console.log('variant', variant);
  // 				return {
  // 					id: variant.sys.id, // Required
  // 					// Map any other fields required by your rendering component
  // 					...variant
  // 				}
  // 			})
  // 		}
  // })

  // console.log('mappedExperiences', mappedExperiences);
  // console.log('mappedExperiences isExperienceEntry', mappedExperiences
  // 	.filter((experience) => ExperienceMapper.isExperienceEntry(experience)));

  // const filteredMappedExperiences = mappedExperiences
  // 	.filter((experience) => ExperienceMapper.isExperienceEntry(experience))
  // 	.map((experience) => ExperienceMapper.mapExperience(experience));
  // console.log('filteredMappedExperiences', filteredMappedExperiences);

  // pass a hardcoded parameter to isExperience entry that evaluates to true
  // console.log(ExperienceMapper.isExperienceEntry());

  // return hasExperiences(entry)
  //   ? entry.fields.nt_experiences
  //       .filter((experience) => ExperienceMapper.isExperienceEntry(experience))
  //       .map((experience) => ExperienceMapper.mapExperience(experience))
  //   : [];
};

export var hoistId = function hoistId(entry) {
  if (entry) {
    return _objectSpread(_objectSpread({}, entry), {}, {
      id: entry.sys.id
    });
  }
  return {
    id: ''
  };
};

// Feature flagging example
// export const useFlag = (flag) => {
//   const settings = useContext(SettingsContext);
//   const setting = settings[flag];

//   const { variantIndex } = useExperience({
//     baseline: hoistId(setting),
//     experiences: setting ? parseExperiences(setting) : [],
//   });

//   return variantIndex;
// };